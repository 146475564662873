import React, { useContext, useEffect, useState } from 'react';

import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-confirm-alert.css';

import { Cell, HeadCell, TableHeader, Row, Table, TableContainer } from '../shared/styledComponents';

import data from '../../data';
import { ThemeContext } from '../../contexts/themeContext';

function numberFormat(number) {
	return new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP'
	}).format(number);
}

const OrderReleasedTable = ({date, newRecordId, token, canEditOrdersReleased, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({ordersReleased: null});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const ordersReleased = await data.getOrdersReleased(token, date);
			if(ordersReleased) {
				setPageInfo({ordersReleased});
			}
		}
		
		setupPage();
	}, [token, newRecordId, date]);

	function deleteOrderReleased(orderReleasedId) {
		confirmAlert({
			message: 'Are you sure you want to delete the order released?',
			buttons: [
				{
					label: 'OK',
					onClick: () => {
						deleteOrderReleasedFromDatabase(orderReleasedId);
					}
				},
				{
					label: 'Cancel'
				}
			]
		});
	}

	async function deleteOrderReleasedFromDatabase(orderReleasedId) {
		const hasBeenDeleted = await data.deleteOrderReleased(token, orderReleasedId);

		if (hasBeenDeleted) {
			addMessage('The order released has been successfully deleted.', 3500, false);
			const ordersReleased = await data.getOrdersReleased(token, date);
			if (ordersReleased) {
				setPageInfo({ordersReleased});
			}
		}
		else {
			addMessage(
				"The order released couldn't be deleted, please try again or contact support.",
				3500,
				false
			);
		}
	}

	return (
		<div>
			{(!pageInfo.ordersReleased || (pageInfo.ordersReleased && pageInfo.ordersReleased.length === 0)) && (
				<div>No orders released found for this date.</div>
			)}
			{pageInfo.ordersReleased &&
				pageInfo.ordersReleased.length > 0 && (
					<TableContainer theme={theme}>
						<Table theme={theme} >
							<TableHeader theme={theme}>
								<tr>
									<HeadCell theme={theme}>Ref no</HeadCell>
									<HeadCell theme={theme}>Sales Processor</HeadCell>
									<HeadCell theme={theme}>Project Manager</HeadCell>
									<HeadCell theme={theme}>Release Week</HeadCell>
									<HeadCell theme={theme}>Release Year</HeadCell>
									<HeadCell theme={theme}>Amount(£)</HeadCell>
									<HeadCell theme={theme} style={{ width: '80px' }} />
								</tr>
							</TableHeader>
							<tbody>
								{pageInfo.ordersReleased.map((item, index) => {
									return (
										<Row theme={theme} key={index}>
											<Cell theme={theme}>{item.referenceNumber}</Cell>
											<Cell theme={theme}>{item.salesUserName}</Cell>
											<Cell theme={theme}>{item.managerUserName}</Cell>
											<Cell theme={theme}>{item.weekNumber}</Cell>
											<Cell theme={theme}>{item.yearReleased}</Cell>
											<Cell theme={theme}>{numberFormat(item.totalCost)}</Cell>

											<Cell theme={theme}>
												{canEditOrdersReleased && (
													<button
														onClick={() => {
															deleteOrderReleased(item.id);
														}}
													>
														Delete
													</button>
												)}
											</Cell>
										</Row>
									);
								})}
							</tbody>
						</Table>
					</TableContainer>
				)}
		</div>
	);
}

export default OrderReleasedTable;
