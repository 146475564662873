//order released by production week
import React, { useEffect, useState } from 'react';

import data from '../../data';

import SimpleChart from '../simpleChart';
import SelectYear from '../../components/shared/selectYear';

const OrderReleasedByWeekReleasedChart = ({token}) => {
	const [pageInfo, setPageInfo] = useState({ordersReleasedByReleaseWeek: [], selectedYear: null});

	useEffect(() => {
		const setupPage = async () => {
			const THIS_YEAR = new Date().getFullYear();
			const ordersReleasedByReleaseWeek = await data.getOrdersReleasedByWeekNumber(token, THIS_YEAR);

			setPageInfo({
				token,
				selectedYear: THIS_YEAR,
				ordersReleasedByReleaseWeek
			});
		}

		setupPage();
	}, [token]);

	async function handleOrderReleasedYearChange(selectedYear) {
		const ordersReleasedByReleaseWeek = await data.getOrdersReleasedByReleaseWeek(pageInfo.token, selectedYear);
		setPageInfo({
			token: pageInfo.token,
			selectedYear,
			ordersReleasedByReleaseWeek
		});
	};

	return (
		<div>
			<SelectYear onYearChange={handleOrderReleasedYearChange} />
			<SimpleChart
				chartData={pageInfo.ordersReleasedByReleaseWeek}
				XAxisDataKey="weekNumber"
				XAxisLabel="Week Number"
				YAxisLabel="Orders Released Total (£)"
				YAxisDataKey="ordersReleasedSum"
				brush={{ dataKey: 'weekNumber' }}
			/>
		</div>
	);
}

export default OrderReleasedByWeekReleasedChart;
