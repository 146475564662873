import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import {
	Cell,
	HeadCell, 
	PageHeading,
	Row,
	Table,
	TableHeader,
	TableContainer
} from "../shared/styledComponents";

import { ThemeContext } from '../../contexts/themeContext';
import data from "../../data";

const PersonsTable = ({token}) => {
	const [pageInfo, setPageInfo] = useState({isLoading: true, canEditPeople: false, persons: null});
	const { theme } = useContext(ThemeContext);

	const navigate = useNavigate();

	useEffect(() => {
		const setupPage = async () => {
			let persons = null;
			let canEditPeople = false;

			const response = await data.getAllPersons(token);
			if (response) {
				persons = response;
			}
			const permissions = await data.getUserPermissions(token);
			if (permissions && permissions.canEditPeople) {
				canEditPeople = permissions.canEditPeople;
			}

			setPageInfo({
				isLoading: false,
				canEditPeople,
				persons
			});
		}

		setupPage();
	}, [token]);

	function onEdit(personId) {
		navigate(`/personForm/${personId}`);
	}

	return (
		<div
			style={{
				marginRight: "25px",
				marginLeft: "10px",
				marginTop: "10px",
				marginBottom: "10px",
				paddingLeft: "25px",
				width: "100%"
			}}
		>
			<PageHeading theme={theme}>Persons</PageHeading>
			<br />
			{pageInfo.canEditPeople && (
				<Link style={{ float: "right" }} to="/personForm">
					<button
						style={{
							cursor: "pointer",
							marginRight: "20px",
							marginBottom: "10px",
							padding: "2px 10px 2px 10px"
						}}
						type="submit"
					>
						Add new person
					</button>
				</Link>
			)}
			{pageInfo.isLoading && <div>Loading</div>}
			{!pageInfo.isLoading && !pageInfo.persons && <div>There are no persons in database.</div>}
			{!pageInfo.isLoading && pageInfo.persons && (
				<TableContainer theme={theme}>
					<Table theme={theme}>
						<TableHeader theme={theme}>
							<tr> 
								<HeadCell theme={theme}>First Name</HeadCell>
								<HeadCell theme={theme}>Last Name</HeadCell>
								<HeadCell theme={theme}style={{ width: "80px" }} />
							</tr>
						</TableHeader>
						<tbody>
							{pageInfo.persons.map((person, index) => {
								return (
									<Row theme={theme} key={index}>
										<Cell theme={theme}>{person.firstName}</Cell>
										<Cell theme={theme}>{person.lastName}</Cell>
										<Cell theme={theme}>
											{pageInfo.canEditPeople && (
												<button
													onClick={() => {
														onEdit(person.id);
													}}
												>
													Edit
												</button>
											)}
										</Cell>
									</Row>
								);
							})}
						</tbody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}

export default PersonsTable;
