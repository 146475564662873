import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../contexts/themeContext";

const Tab = styled.div(
  ({ theme, themeKey }) => `
  color: ${theme.colors.header.color};
  z-index: -1;
  position: absolute;
  top: 56px;
  width: 55px;
  border: 1px solid ${theme.colors.header.tabBorder};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 15px 8px 15px;
  text-align: center;
  z-index: 90; 
  ${
    themeKey === "dayMode" &&
    `background-image: linear-gradient(
    ${theme.colors.header.tabBackgroundLight},
    ${theme.colors.header.tabBackground});`
  }
`
);

const ActiveTab = styled(Tab)(
  ({ theme, themeKey }) => `
  ${
    themeKey === "dayMode"
      ? `background-image: linear-gradient(${theme.colors.header.tabBackgroundLight}, white );`
      : `background-color: ${theme.colors.header.tabBackgroundDark};`
  }
    font-weight: bold; 
    border-bottom: 1px solid ${theme.colors.header.activeTabBorder};
    color: ${theme.colors.header.color};
  `
);

const Link = styled.a(
  ({ theme }) => `
  text-decoration: none;
  color: #000000;
  padding: 8px 15px 8px 15px;

  &:visited {
    color: ${theme.colors.header.colorVisited};
  }
  &:hover {
    color: ${theme.colors.pageHeading};
  }
`
);

const HeaderTab = (props) => {
  const { theme, themeKey } = useContext(ThemeContext);  
  return (
    <div>
      {props.isActive ? (
        <ActiveTab
          theme={theme}
          themeKey={themeKey}
          style={{ right: props.rightOffset }}
        >
          {props.label}
        </ActiveTab>
      ) : (
        <Tab
          theme={theme}
          themeKey={themeKey}
          style={{ right: props.rightOffset }}
        >
          <Link theme={theme} href={props.link}>
            {props.label}
          </Link>
        </Tab>
      )}
    </div>
  );
};

export default HeaderTab;
