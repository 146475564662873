import React, { useContext, useEffect, useState } from 'react';

import { NumericFormat } from 'react-number-format';

import OrderReleasedTable from './ordersReleasedTable';

import SelectDate from '../shared/selectDate';

import { todaysDate } from '../../lib/getDate';

import data from '../../data';
import {
	PageHeading,
	StyledLabel,
	FixedWidthInput,
	FieldContainer,
	FixedWidthSelect,
	CointainerStyle
} from '../shared/styledComponents';
import { ThemeContext } from '../../contexts/themeContext';

const moneyStyle = {
	cursor: 'pointer',
	width: '35%',
	padding: '8px',
	borderRadius: '5px',
	border: '1px solid #dedede'
};

function checkCostIsAllowed(values) {
	const { floatValue } = values;
	return floatValue >= 0 && floatValue <= 9999999.99;
}

function ordersReleasedYears() {
	const firstYear = 2017;
	const currentYear = new Date().getFullYear();
	let allYears = [];

	for (let i = firstYear; i <= currentYear + 2; i++) {
		allYears.push(
			<option key={i} value={i}>
				{i}
			</option>
		);
	}
	return allYears;
}

async function getRolesForUser(token) {
	const roleIdArray = [2, 3];
	const roles = await data.getRolesForPersons(token, roleIdArray);
	return roles;
}

const OrdersReleasedForm = ({token, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({isLoading: true, canEditOrdersReleased: false});
	const [staffInfo, setStaffInfo] = useState({salesPersons: [], projectManagers: []});
	const [orderReleasedInfo, setOrderReleasedInfo] = useState({
		newRecordId: undefined,
		referenceNumber: 0,
		weekNumber: 0,
		totalCost: 0,
		salesPersonId: -1,
		managerPersonId: -1,
		yearReleased: new Date().getFullYear(),
		selectedDate: todaysDate()
	});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const rolesUser = await getRolesForUser(token);
			const permissions = await data.getUserPermissions(token);
			let canEditOrdersReleased = false;
			if (permissions && permissions.canEditOrdersReleased) {
				canEditOrdersReleased = permissions.canEditOrdersReleased;
			}

			let salesPersons = null;
			let projectManagers = null;
			if (rolesUser && rolesUser.length > 1) {
				salesPersons = rolesUser[0];
				projectManagers = rolesUser[1];
			}

			setPageInfo({
				isLoading: false,
				canEditOrdersReleased,
				token
			});
			setStaffInfo({salesPersons, projectManagers});
		}

		setupPage();
	}, [token]);

	async function onDateChange(selectedDate) {
		setOrderReleasedInfo({...orderReleasedInfo, selectedDate});
	}	

	async function onHandleSubmit(event) {
		event.preventDefault();
		if (orderReleasedInfo.salesPersonId !== -1 && orderReleasedInfo.managerPersonId !== -1) {		
			const orderReleased = {
				orderReleasedDate: orderReleasedInfo.selectedDate,
				referenceNumber: orderReleasedInfo.referenceNumber,
				weekNumber: orderReleasedInfo.weekNumber,
				yearReleased: orderReleasedInfo.yearReleased,
				salesPersonId: orderReleasedInfo.salesPersonId,
				managerPersonId: orderReleasedInfo.managerPersonId,
				totalCost: orderReleasedInfo.totalCost
			};

			const ordersReleasedAddResponse = await data.insertOrderReleased(pageInfo.token, orderReleased);
			
			if (ordersReleasedAddResponse) {
				if (ordersReleasedAddResponse !== '' && ordersReleasedAddResponse.id) {
					addMessage('The order released has been successfully saved', 3500, false);
				}
				else {
					addMessage('An error has occured, please try again or contact support', 3500, true);
				}
			}

			const newState = {
				newRecordId: ordersReleasedAddResponse.id,
				referenceNumber: 0,
				weekNumber: 0,
				totalCost: 0,				
				salesPersonId: -1,
				managerPersonId: -1,
				yearReleased: orderReleasedInfo.yearReleased,
				selectedDate: orderReleasedInfo.selectedDate
			};

			const rolesUser = await getRolesForUser(pageInfo.token);
			const salesPersons = rolesUser[0];
			const projectManagers = rolesUser[0];

			setOrderReleasedInfo(newState);
			setStaffInfo({salesPersons, projectManagers});
		}
	}

	async function handleSalesPersonChange(event) {
		setOrderReleasedInfo({...orderReleasedInfo, salesPersonId: event.target.value});
	}

	async function handleProjectManagerChange(event) {
		setOrderReleasedInfo({...orderReleasedInfo, managerPersonId: event.target.value});
	}

	async function handleYearChange(event) {
		setOrderReleasedInfo({...orderReleasedInfo, yearReleased: event.target.value});
	}

	async function handleInputChange(event) {
		let newState = {...orderReleasedInfo};
		newState[event.target.name] = event.target.value;
		setOrderReleasedInfo(newState);
	}

	async function handleTotalCostChange(values) {
		setOrderReleasedInfo({...orderReleasedInfo, totalCost: values.value});
	}	

	return (
		<CointainerStyle theme={theme}>
			<PageHeading theme={theme}>Orders Released</PageHeading>
			<br />
			<div style={{width:'671px'}}>
				<form onSubmit={onHandleSubmit} autoComplete="off">
					<SelectDate onDateChange={onDateChange} />
					<hr />
					{pageInfo.isLoading && <div>Loading...</div>}
					{!pageInfo.isLoading &&
						pageInfo.canEditOrdersReleased && (
							<div>
								<FieldContainer theme={theme}>
									<StyledLabel theme={theme}>Reference Number:</StyledLabel>
									<FixedWidthInput theme={theme}
										type="text"
										name={'referenceNumber'}
										min={0}
										required={true}
										value={orderReleasedInfo.referenceNumber}
										onChange={handleInputChange}
									/>
								</FieldContainer>
								<FieldContainer theme={theme}>
									<StyledLabel theme={theme}>Release Week Number:</StyledLabel>
									<FixedWidthInput 
										theme = {theme}
										type="number"
										name={'weekNumber'}
										required={true}
										min={1}
										max={53}
										value={orderReleasedInfo.weekNumber}
										onChange={handleInputChange}
									/>
								</FieldContainer>

								<FieldContainer theme={theme} >
									<StyledLabel theme={theme} >Release Year:</StyledLabel>
									<FixedWidthSelect theme={theme} value={orderReleasedInfo.yearReleased} onChange={handleYearChange}>
										{ordersReleasedYears()}
									</FixedWidthSelect>
								</FieldContainer  >

								<FieldContainer theme={theme} >
									<StyledLabel theme={theme} >Total Cost:</StyledLabel>
									<NumericFormat
										style={moneyStyle}
										thousandSeparator={','}
										decimalSeparator={'.'}
										precision={5}
										decimalScale={2}
										prefix={'£ '}
										value={orderReleasedInfo.totalCost}
										valueIsNumericString={true}
										onValueChange={handleTotalCostChange}
										isAllowed={checkCostIsAllowed}
									/>
								</FieldContainer>
								<FieldContainer theme={theme} >
									<StyledLabel theme={theme} >Sales:</StyledLabel>
									<FixedWidthSelect theme={theme} 
										value={orderReleasedInfo.salesPersonId}
										onChange={handleSalesPersonChange}
										required={true}
									>
										<option value={''}>Please select</option>
										{staffInfo.salesPersons &&
											staffInfo.salesPersons.map((salesPerson, index) => {
												return (
													<option key={index} value={salesPerson.personId}>
														{salesPerson.firstName}
													</option>
												);
											})}
									</FixedWidthSelect>
								</FieldContainer>
								<FieldContainer theme={theme} >
									<StyledLabel theme={theme} >Project Manager:</StyledLabel>
									<FixedWidthSelect theme={theme} 
										value={orderReleasedInfo.managerPersonId}
										onChange={handleProjectManagerChange}
										required={true}
									>
										<option value={''}>Please select</option>
										{staffInfo.projectManagers &&
											staffInfo.projectManagers.map((projectManager, index) => {
												return (
													<option key={index} value={projectManager.personId}>
														{projectManager.firstName}
													</option>
												);
											})}
									</FixedWidthSelect>
								</FieldContainer>

								<br />
								<button
									style={{
										cursor: 'pointer',
										float: 'right',
										marginRight: '155px',
										padding: '2px 10px 2px 10px'
									}}
									type="submit"
								>
									Save
								</button>
								<br />
							</div>
						)}
				</form>
			</div>
			<br />
			<hr />
			{!pageInfo.isLoading && (
				<OrderReleasedTable
					date={orderReleasedInfo.selectedDate}
					newRecordId={orderReleasedInfo.newRecordId}
					token={token}
					canEditOrdersReleased={pageInfo.canEditOrdersReleased}
					addMessage={addMessage}
				/>
			)}
			<br />
		</CointainerStyle>
	);
}

export default OrdersReleasedForm;
