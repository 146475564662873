import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { At } from '@styled-icons/boxicons-regular/At';
import { Phone } from '@styled-icons/boxicons-solid/Phone';
import { Envelope } from '@styled-icons/boxicons-solid/Envelope';
import { Book } from '@styled-icons/octicons/Book';
import { Graph } from '@styled-icons/octicons/Graph';

import { ThemeContext } from '../contexts/themeContext';
import data from '../data';

import {
	PageHeading,
	StyledTable,
	StyledTd,
	CentredTd,
	StyledStrong,
	StyledTr
} from './shared/styledComponents';

import { previousWeekDayDate } from '../lib/getDate';

const TODAYS_DATE = new Date();
const INITIAL_MONTH = 3;
const INITIAL_YEAR = 2017;

const defaultDashboardInfo = {
	totalNumberOfEnquiries: 0,
	totalByEmail: 0,
	totalByPhone: 0,
	totalByBrochure: 0,
	totalByPost: 0,
	totalNumberOfQuotes: 0,
	quotesAmount: 0,
	totalNumberOfOrdersSecured: 0,
	ordersSecuredAmount: 0,
	estimatePendingTotal: 0,
	commercialReviewTotal: 0,
	totalNumberOfOrdersReleased: 0,
	ordersReleasedAmount: 0,
	selectedYear: 0,
	selectedMonth: 0
};

function numberFormat(value) {
	return new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP'
	}).format(value);
}

function loopOverAvailableMonthsData(selectedYear, selectedMonth) {
	let months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	let dateSelected = [];
	let allowedMonth = selectedMonth;

	let initialMonth = 1;

	for (let i = selectedYear, index = 0; i >= INITIAL_YEAR; i--) {
		if (INITIAL_YEAR === i) {
			initialMonth = INITIAL_MONTH;
		}
		for (let j = allowedMonth; j >= initialMonth; j--, index++) {
			dateSelected.push(
				<option key={index} year={i} month={j}>
					{months[j - 1]} {i}
				</option>
			);
		}
		allowedMonth = 12;
	}
	return dateSelected;
};

async function setDataForReport(selectedYear, selectedMonth, token, addMessage) {
	const response = await data.getSummaryReport(token, selectedYear, selectedMonth);
	let reportData = null;
	if (response) {
		reportData = response;
	} else {
		addMessage('The data is not being loaded, please contact support', 3500, true);
	}
	return reportData;
}

async function setCommercialReviewAndEstimatePendingReport(previousWeekDayDate, token) {
	const response = await data.getCommercialReviewAndEstimatePendingReport(
		token,
		previousWeekDayDate
	);
	let dashboardInfo = null;
	if (response) {
		dashboardInfo = response;
	}
	return dashboardInfo;
}

const Dashboard = ({token, addMessage}) => {
	const [dashboardInfo, setDashboardInfo] = useState(defaultDashboardInfo);
	const [pageInfo, setPageInfo] = useState({isLoading: true, allMonths: []});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			let selectedYear = TODAYS_DATE.getFullYear();
			let selectedMonth = TODAYS_DATE.getMonth() + 1;
			let allMonths = loopOverAvailableMonthsData(selectedYear, selectedMonth);
			let lastWorkingDay = previousWeekDayDate();

			let newdashboardInfo = await setDataForReport(selectedYear, selectedMonth, token, addMessage);
			const commercialReviewAndEstimatePendingReport = await setCommercialReviewAndEstimatePendingReport(lastWorkingDay.format('YYYY-MM-DD'), token);
			setPageInfo({
				isLoading: false,
				token,
				allMonths,
				lastWorkingDay: lastWorkingDay.format('DD/MM/YY'),
				commercialReviewAndEstimatePendingReport
			});
			setDashboardInfo(newdashboardInfo);
		}

		setupPage();
	}, [token, addMessage]);	

	async function handleMonthChange(event) {
		const selectedOption = event.target.options[event.target.selectedIndex];
		const selectedYear = selectedOption.attributes.year.value;
		const selectedMonth = selectedOption.attributes.month.value;
		let dashboardInfo = await setDataForReport(selectedYear, selectedMonth, pageInfo.token, addMessage);
		dashboardInfo.selectedYear = selectedYear;
		dashboardInfo.selectedMonth = selectedMonth;

		setDashboardInfo(dashboardInfo);
	};

	return(
		<div
			style={{
				marginRight: '25px',
				marginTop: '10px',
				marginBottom: '10px',
				paddingLeft: '25px',
				width: '100%'
			}}
		>
			<PageHeading theme={theme}>MW Dashboard</PageHeading>
			<br />
			{pageInfo.isLoading && <div>Loading Page</div>}
			{!pageInfo.isLoading && (
				<div>
					<div style={{ display: 'flex' }}>
						<p>
							Month of
							<select
								style={{
									marginLeft: '10px',
									fontWeight: 'bold',
									fontSize: '11pt',
									height: '25px'
								}}
								onChange={handleMonthChange}
							>
								{pageInfo.allMonths}
							</select>
						</p>
						<br />
						<br />
					</div>
					<StyledTable theme={theme}>
						<tbody>
							<tr>
								<td>{dashboardInfo.totalNumberOfEnquiries}</td>
								<td>Enquiries</td>
								<StyledTd theme={theme} >
									{dashboardInfo.totalByEmail}
									<At
										size={25}
										style={{
											marginRight: '10px',
											marginLeft: '2px'
										}}
									/>
									{dashboardInfo.totalByPhone}
									<Phone
										size={25}
										style={{
											marginRight: '8px',
											marginLeft: '2px'
										}}
									/>
									{dashboardInfo.totalByBrochure}
									<Book
										size={25}
										style={{
											marginRight: '10px',
											marginLeft: '2px'
										}}
									/>
									{dashboardInfo.totalByPost}
									<Envelope
										size={25}
										style={{
											marginRight: '10px',
											marginLeft: '2px'
										}}
									/>
								</StyledTd>
								<CentredTd theme={theme}>
									<Link to="/chart/enquiries">
										<Graph size={25} style={{ color: theme.colors.icon.graph }} />
									</Link>
								</CentredTd>
							</tr>
							<StyledTr theme={theme}>
								<td>{dashboardInfo.totalNumberOfQuotes}</td>
								<td>Quotes</td>
								<StyledTd theme={theme}>{numberFormat(dashboardInfo.quotesAmount)}</StyledTd>
								<CentredTd theme={theme}>
									<Link to="/chart/quotes">
										<Graph size={25} style={{ color: theme.colors.styledTr.color }} />
									</Link>
								</CentredTd>
							</StyledTr>
							<tr>
								<td>{dashboardInfo.totalNumberOfOrdersSecured}</td>
								<td>Orders secured</td>
								<StyledTd theme={theme}>
									{numberFormat(dashboardInfo.ordersSecuredAmount)}
								</StyledTd>
								<CentredTd theme={theme}>
									<Link to="/chart/ordersSecured">
										<Graph size={25} style={{ color: theme.colors.icon.graph }} />
									</Link>
								</CentredTd>
							</tr>
							<StyledTr  theme={theme}>
								<td>{dashboardInfo.totalNumberOfOrdersReleased}</td>
								<td>Orders released</td>
								<StyledTd theme={theme}>
									{numberFormat(dashboardInfo.ordersReleasedAmount)}
								</StyledTd>
								<CentredTd theme={theme}>
									<Link to="/chart/orderReleased">
										<Graph size={25} style={{ color: theme.colors.styledTr.color  }} />
									</Link>
								</CentredTd>
							</StyledTr>
						</tbody>
					</StyledTable>
					<hr />
					<br />
					<StyledStrong theme={theme}>
						Running total from last working day ({pageInfo.lastWorkingDay})
					</StyledStrong>
					<br />
					<br />
					<StyledTable theme={theme} cellpadding={4}>
						<tbody>
							<tr>
								<td>
									{(pageInfo.commercialReviewAndEstimatePendingReport &&
										pageInfo.commercialReviewAndEstimatePendingReport.commercialReview) ||
										0}
								</td>
								<td>Projects for Commercial Review</td>
								<CentredTd theme={theme}>
									<Link to="/chart/commercialReview">
										<Graph size={25} style={{ color: theme.colors.icon.graph }} />
									</Link>
								</CentredTd>
							</tr>
							<StyledTr theme={theme}>
								<td>
									{(pageInfo.commercialReviewAndEstimatePendingReport &&
										pageInfo.commercialReviewAndEstimatePendingReport.estimatePending) ||
										0}
								</td>
								<td>Projects with Estimate Pending</td>
								<CentredTd theme={theme}>
									<Link to="/chart/estimatePending">
										<Graph size={25}  style={{ color: theme.colors.styledTr.color  }}/>
									</Link>
								</CentredTd>
							</StyledTr>
						</tbody>
					</StyledTable>
					<br />
					<br />					
				</div>
			)}
		</div>
	);
}

export default Dashboard;
