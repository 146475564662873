import React, { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  THEMES,
  THEME_LABELS,
  DEFAULT_THEME,
  NIGHT_MODE_KEY,
  THEME_STORAGE_NAME,
} from "../../constants";

const STORAGE_TYPE = "localStorage";

export const Context = createContext({});

export const Provider = ({ children, defaultThemeKey }) => {  const [cookie, setCookie] = useCookies();
  const [themeState, setThemeState] = useState(DEFAULT_THEME);

  const getUserTheme = {
    cookie: () => {
      console.info("fething user theme from cookie.....");
      return cookie[THEME_STORAGE_NAME] ?? undefined;
    },
    localStorage: () => {
      console.info("fething user theme using local storage.....");
      return localStorage.getItem(THEME_STORAGE_NAME);
    },
  };

  const setUserTheme = {
    cookie: (themeKey) => {
      console.info("setting user theme from cookie.....");
      setCookie(THEME_STORAGE_NAME, themeKey, {
        path: "/",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365), // 1 year
      });
    },
    localStorage: (themeKey) => {
      console.info("setting user theme using local storage.....");
      localStorage.setItem(THEME_STORAGE_NAME, themeKey);
    },
  };

  const setTheme = async (themeKey = false) => {
    //if no theme passed, then function will act as a toggle between defaultTheme (day mode) and night mode
    if (!themeKey) {
      if (themeState.key === DEFAULT_THEME.key) {
        themeKey = NIGHT_MODE_KEY;
      } else {
        themeKey = DEFAULT_THEME.key;
      }
    }

    setUserTheme[STORAGE_TYPE](themeKey);

    setThemeState({
      label: THEME_LABELS[themeKey],
      key: themeKey,
      theme: THEMES[themeKey],
    });
  };

  useEffect(() => {
    const setThemeConfig = async () => {
      try {
        const themeKey =
          getUserTheme[STORAGE_TYPE]() ?? defaultThemeKey ?? DEFAULT_THEME.key; 
        setTheme(themeKey);
      } catch (e) {
        console.error(e);
      }
    };
    setThemeConfig();
  }, []);

  const themeContext = {
    setTheme,
    theme: themeState?.theme,
    themeKey: themeState?.key,
    themeLabel: THEME_LABELS[themeState?.key],
    isDefault: themeState?.key === DEFAULT_THEME?.key,
  };

  return <Context.Provider value={themeContext}>{children}</Context.Provider>;
};
