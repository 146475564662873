import { useContext } from "react";

import { useLocation } from 'react-router-dom';
import { SideMenuLink, SideMenuDiv, SideMenuWhiteDiv } from "./styledSideMenu";
import { ThemeContext } from "../../contexts/themeContext";
import ThemeToggle from "../themeToggle";

const SideMenu = ({ name }) => {
	const { theme } = useContext(ThemeContext);
	const { pathname } = useLocation();

	return (
		<div>
			<style>{`.sideMenuWrapper > { border: 1px solid ;}`}</style>
			<SideMenuDiv theme={theme} style={{ marginRight: "20px" }}>
				<SideMenuWhiteDiv theme={theme}>
					<span>Welcome {name}</span> 
				</SideMenuWhiteDiv>
				<ThemeToggle />
				<SideMenuLink theme={theme} to="/" isSelected={pathname === "/"}>
					Home
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/dashboard" isSelected={pathname === "/dashboard"}>
					Dashboard
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/enquiries" isSelected={pathname === "/enquiries"}>
					Enquiries
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/quotes" isSelected={pathname === "/quotes"}>
					Quotes
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/ordersSecured" isSelected={pathname === "/ordersSecured"}>
					Orders Secured
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/ordersReleased" isSelected={pathname === "/ordersReleased"}>
					Orders Released
				</SideMenuLink>
				<SideMenuLink theme={theme} to="/personsTable" isSelected={pathname === "/personsTable"}>
					Persons
				</SideMenuLink>
			</SideMenuDiv>
		</div>
	);
}

export default SideMenu;
