import React, { useContext } from "react";
import { NIGHT_MODE_STR } from "../../constants";
import { ThemeContext } from "../../contexts/themeContext";
import ToggleSwitch from "../toggle";

const Index = ({ isChecked }) => {
  const { theme, setTheme, isDefault } = useContext(ThemeContext);

  if (!theme || Object.keys(theme).length < 1) {
    console.error(`No theme detected. Found ${JSON.stringify(theme)}`); 
    return <></>;
  }

  const handleThemeToggle = () => {
    setTheme();
  };

  return (
    <>
      <style jsx="true">{`
        #theme-toggle-wrapper {
          height: fit-content;
          width: 100%;
          display: flex;
          color:${theme.colors.themeToggle.wrapper.color};
        }
      `}</style>
      <div id="theme-toggle-wrapper">
        <ToggleSwitch
          border={`border-bottom: 1px solid ${theme.colors.themeToggle.border}`}
          id={"theme-toggle"}
          text={`Toggle ${NIGHT_MODE_STR}`}
          defaultChecked={isChecked ? isChecked : !isDefault}
          onChange={handleThemeToggle}
        />
      </div>
    </>
  );
};
export default Index;
