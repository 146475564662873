import React, { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { todaysDate } from '../lib/getDate';

import data from '../data';

import SelectDate from './shared/selectDate';
import {
	PageHeading,
	FieldContainer,
	FixedWidthLabel,
	CointainerStyle,
	StyledButton,
	StyledFixedWidthInput
} from './shared/styledComponents';
import { ThemeContext } from '../contexts/themeContext';

/*****************************************************************************/

const moneyStyle = {
	cursor: 'pointer',
	width: '150px',
	padding: '8px',
	borderRadius: '5px',
	border: '1px solid #dedede'
};

const defaultQuotesForDate = {
	id: null,
	commercialReview: 0,
	estimatePending: 0,
	quoteDetails: [],
	quoteDate: todaysDate()
};

function checkCostIsAllowed(values) {
	const { floatValue } = values;
	const isAllowed = floatValue >= 0 && floatValue <= 9999999.99;
	return isAllowed;
}

/*****************************************************************************/

const Quotes = ({token, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({isLoading: true, isInEditMode: false, token});
	const [quotesData, setQuotesData] = useState(defaultQuotesForDate);

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const roleId = 1;
			let quotesForDate = defaultQuotesForDate;
			let isInEditMode = false;

			let quoteDate = defaultQuotesForDate.quoteDate;
			const quote = await data.getQuoteForDate(token, quoteDate);
			const quoteDetails = await data.getDailyQuotingActivityPerPerson(token, quoteDate, roleId);

			if (quote) {
				quotesForDate = {
					id: quote.id,
					commercialReview: quote.commercialReview,
					estimatePending: quote.estimatePending,
					quoteDate
				};
				isInEditMode = true;
			}

			if (quoteDetails) {
				quotesForDate.quoteDetails = quoteDetails;
			}
			setPageInfo({isLoading: false, isInEditMode, token});
			setQuotesData(quotesForDate);
		}
		setupPage();
	}, [token]);

	async function onHandleSubmit(event) {
		event.preventDefault();

		let response = null;
		if (pageInfo.isInEditMode) {
			response = await data.updateQuote(
				pageInfo.token,
				quotesData.id,
				quotesData.quoteDate,
				quotesData.commercialReview,
				quotesData.estimatePending,
				quotesData.quoteDetails
			);
		}
		else {
			response = await data.insertQuote(
				pageInfo.token,
				quotesData.quoteDate,
				quotesData.commercialReview,
				quotesData.estimatePending,
				quotesData.quoteDetails
			);
		}
		if (response && response.id) {
			handleQuoteDateChange(quotesData.quoteDate);
		}

		if (response && response !== '' && response.id) {
			addMessage('Quotes for this date have been successfully saved', 3500, false);
		}
		else {
			addMessage('An error has occurred, please try again or contact support', 3500, true);
		}
	}

	async function handleQuoteDateChange(date) {
		const roleId = 1;
		let isInEditMode = false;

		let quotesForDate = defaultQuotesForDate;
		if(date) {
			quotesForDate.quoteDate = date;
		}

		const quote = await data.getQuoteForDate(pageInfo.token, quotesForDate.quoteDate);
		const quoteDetails = await data.getDailyQuotingActivityPerPerson(pageInfo.token, quotesForDate.quoteDate, roleId);

		let dateToUse = quotesForDate.quoteDate;
		if (quote) {
			quotesForDate = {
				id: quote.id,
				commercialReview: quote.commercialReview,
				estimatePending: quote.estimatePending,
				quoteDate: dateToUse
			};
			isInEditMode = true;
		}

		if (quoteDetails) {
			quotesForDate.quoteDetails = quoteDetails;
		}
		setPageInfo({...pageInfo, isInEditMode, isLoading: false});
		setQuotesData(quotesForDate);
	}

	async function handleInputChange(event) {
		let quotesForDate = { ...quotesData };
		quotesForDate[event.target.name] = event.target.value;
		setQuotesData(quotesForDate);
	}

	async function handleInputChangeTotal(index, event) {
		let inputValue = 0;
		if (event.target.value) {
			inputValue = event.target.value;
		}

		let quotesForDate = { ...quotesData };
		quotesForDate.quoteDetails[index][event.target.name] = inputValue;
		setQuotesData(quotesForDate);
	}

	async function handleCostChange(index, values) {
		const { value } = values;
		let quotesForDate = { ...quotesData };
		quotesForDate.quoteDetails[index].totalCost = value;
		setQuotesData(quotesForDate);
	}

	return (
		<CointainerStyle theme={theme} >
			<PageHeading theme={theme}>Quotes</PageHeading>
			<br />
			{pageInfo.isLoading && <div>Loading</div>}
			{!pageInfo.isLoading && (
				<form onSubmit={onHandleSubmit} autoComplete="off">
					<SelectDate theme={theme} onDateChange={handleQuoteDateChange} />
					<hr />
					<FieldContainer theme={theme} >
						<FixedWidthLabel theme={theme} >Commercial Review:</FixedWidthLabel>
						<StyledFixedWidthInput theme={theme} 
							type="number"
							name={'commercialReview'}
							min={0}
							max={99999}
							required={true}
							value={quotesData.commercialReview ? quotesData.commercialReview : 0}
							onChange={handleInputChange}
						/>
					</FieldContainer>
					<FieldContainer theme={theme} >
						<FixedWidthLabel theme={theme} >Estimate Pending:</FixedWidthLabel>
						<StyledFixedWidthInput theme={theme} 
							type="number"
							name={'estimatePending'}
							min={0}
							max={99999}
							required={true}
							value={quotesData.estimatePending ? quotesData.estimatePending : 0}
							onChange={handleInputChange}
						/>
					</FieldContainer>

					{quotesData.quoteDetails &&
						quotesData.quoteDetails.map((quote, index) => {
							return (
								<div key={index}>
									<FieldContainer theme={theme} >
										<FixedWidthLabel theme={theme} >{quote.firstName}:</FixedWidthLabel>
										<StyledFixedWidthInput theme={theme} 
											type="number"
											name={'totalNumber'}
											min={0}
											required={true}
											value={quote.totalNumber ? quote.totalNumber : 0}
											style={{ marginRight: '10px' }}
											onChange={event => handleInputChangeTotal(index, event)}
										/>
										<NumericFormat
											style={moneyStyle}
											thousandSeparator={','}
											decimalSeparator={'.'}
											precision={5}
											decimalScale={2}
											prefix={'£ '}
											value={quote.totalCost ? quote.totalCost : 0}
											valueIsNumericString={true}
											onValueChange={values => handleCostChange(index, values)}
											isAllowed={values => checkCostIsAllowed(values)}
										/>
									</FieldContainer>
								</div>
							);
						})}
					<br />
					<StyledButton theme={theme} type="submit">
						Save
					</StyledButton>
				</form>
			)}
			<br />
		</CointainerStyle>
	);
}

export default Quotes;
