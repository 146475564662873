import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import data from '../../data';

import {
	PageHeading,
	StyledLabel,
	FixedWidthInput,
	FieldContainer,
	CointainerStyle
} from '../shared/styledComponents';
import { ThemeContext } from '../../contexts/themeContext';

const buttonStyle = {
	cursor: 'pointer',
	margin: '2px',
	padding: '2px 10px 2px 10px'
};

const PersonForm = ({token, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({isLoading: true, isInEditMode: false, firstName: '', lastName: '', roles: [], id: null});

	let { personId } = useParams();
	const navigate = useNavigate();

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {			
			let roles = null;

			let personData = {
				id: personId ? personId : null,
				firstName: '',
				lastName: ''
			};

			const isInEditMode = personData.id ? true : false;

			if (isInEditMode) {
				const newPersonData = await data.getPerson(token, personData.id);
				if (newPersonData) {
					personData.id = newPersonData.id;
					personData.firstName = newPersonData.firstName;
					personData.lastName = newPersonData.lastName;
				}
			}

			const authorizationData = await data.getRolesForPerson(token, personData.id);
			if (authorizationData) {
				roles = authorizationData;
			}

			setPageInfo({
				isLoading: false,
				isInEditMode,
				roles,
				token,
				id: personData.id,
				firstName: personData.firstName,
				lastName: personData.lastName
			});
		}

		setupPage();
	}, [personId, token]);

	async function handleInputChange(event) {
		let newPageInfo = {...pageInfo};
		newPageInfo[event.target.name] = event.target.value;
		setPageInfo(newPageInfo);
	}

	async function handleRoleCheckBoxChange(event, index) {
		let tempRoles = [...pageInfo.roles];
		tempRoles[index].isAuthorised = tempRoles[index].isAuthorised ? 0 : 1;
		setPageInfo({...pageInfo, roles: tempRoles});
	}

	// isPersonActive feature is turned off for the moment
	// function handleIsActiveCheckBoxChange(event) {
		// setPageInfo({...pageInfo, isPersonActive: !pageInfo.isPersonActive})
	// }

	async function onHandleSubmit(event) {
		event.preventDefault();
		let returnValue;
		if (pageInfo.isInEditMode) {
			returnValue = await data.updatePerson(pageInfo.token, pageInfo.id, pageInfo.firstName, pageInfo.lastName, pageInfo.roles);
		} else {
			returnValue = await data.insertPerson(pageInfo.token, pageInfo.firstName, pageInfo.lastName, pageInfo.roles);
		}

		if (returnValue && returnValue !== '') {
			addMessage('The user has been successfully saved', 3500, false);
		} else {
			addMessage('An error has occured, please try again or contact support', 3500, true);
		}
		goBackToPersonTable();
	}

	function goBackToPersonTable() {
		navigate('/personsTable');
	}

	return (
		<CointainerStyle theme={theme}>
			<PageHeading theme={theme}>{pageInfo.isInEditMode ? 'Edit' : 'Add'} Person</PageHeading>
			{pageInfo.isLoading && <div>Loading</div>}
			{!pageInfo.isLoading && (
				<div>
					<br />
					<form onSubmit={onHandleSubmit}>
						<FieldContainer theme={theme}>
							<StyledLabel theme={theme}>First Name:</StyledLabel>
							<FixedWidthInput theme={theme}
								type="text"
								name={'firstName'}
								required={true}
								value={pageInfo.firstName || ''}
								onChange={handleInputChange}
							/>
						</FieldContainer>
						<FieldContainer theme={theme}>
							<StyledLabel theme={theme}>Last Name:</StyledLabel>
							<FixedWidthInput theme={theme}
								type="text"
								name={'lastName'}
								value={pageInfo.lastName || ''}
								onChange={handleInputChange}
							/>
						</FieldContainer>
						<FieldContainer theme={theme}>
							<StyledLabel theme={theme}>Roles:</StyledLabel>
							{pageInfo.roles &&
								pageInfo.roles.map((role, index) => {
									return (
										<div key={index}>
											<input
												id={role.role}
												type="checkbox"
												name={role.role}
												checked={role.isAuthorised ? 1 : 0}
												onChange={event => handleRoleCheckBoxChange(event, index)}
											/>
											<label htmlFor={role.role}>{role.role}</label>
										</div>
									);
								})}
						</FieldContainer>
						<div style={{ textAlign: 'end' }}>
							<button style={buttonStyle} type="submit">
								Save
							</button>
							<button type="button" onClick={goBackToPersonTable} style={buttonStyle}>
								Cancel
							</button>
						</div>
					</form>
				</div>
			)}
		</CointainerStyle>
	);
}

export default PersonForm;
