import React, { useContext, useState } from 'react';
import DatePicker from "react-datepicker";
import "../../css/mw-react-datepicker.css";

import { datepickerFormat } from "../../constants";

import { FieldContainer, FixedWidthLabel } from "./styledComponents";

import { returnDate } from "../../lib/getDate";
import { ThemeContext } from "../../contexts/themeContext";

function isWeekdayAvailable(date) {
	const day = date.getDay();
	return day !== 0 && day !== 6;
}

const SelectDate = ({onDateChange}) => {
	const [dateInfo, setDateInfo] = useState(new Date());
	
	const { theme } = useContext(ThemeContext);

	function handleChange(date) {
		if (date instanceof Date && !isNaN(date) && date.getFullYear() >= 2017) {
			setDateInfo(date);
			onDateChange(returnDate(date));
		}
	}

	return (
		<FieldContainer theme={theme} >
			<FixedWidthLabel theme={theme}>Select Date:</FixedWidthLabel>
			<DatePicker
				selected={dateInfo}
				onChange={(date) => handleChange(date)}
				name="selectedDate"
				dateFormat={datepickerFormat}
				className={"mw-datepicker-input"}
				filterDate={isWeekdayAvailable}
			/>
		</FieldContainer>
	);
}

export default SelectDate;
