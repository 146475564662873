import React, { useContext, useEffect, useState } from 'react';

import data from '../data';

import SimpleChart from './simpleChart';

import { CointainerStyle, PageHeading } from '../components/shared/styledComponents';
import SelectYear from '../components/shared/selectYear';

import { ThemeContext } from '../contexts/themeContext';

const OrderSecuredChart = ({token}) => {
	const [pageInfo, setPageInfo] = useState({ordersSecured: [], selectedYear: null});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const THIS_YEAR = new Date().getFullYear();
			const ordersSecured = await data.getOrdersSecuredByWeek(token, THIS_YEAR);

			setPageInfo({
				token,
				selectedYear: THIS_YEAR,
				ordersSecured
			});
		}

		setupPage();
	}, [token]);

	async function handleOrdersSecuredYearChange(selectedYear) {
		const ordersSecured = await data.getOrdersSecuredByWeek(pageInfo.token, selectedYear);
		setPageInfo({
			token: pageInfo.token,
			selectedYear,
			ordersSecured
		});
	}

	return (
		<CointainerStyle theme={theme} >
			<PageHeading theme={theme}>Orders Secured Chart</PageHeading>
			<SelectYear onYearChange={handleOrdersSecuredYearChange} />
			<SimpleChart
				chartData={pageInfo.ordersSecured}
				XAxisDataKey="weekNumber"
				XAxisLabel="Week Number"
				YAxisLabel="Orders Secured Total(£)"
				YAxisDataKey="totalOrdersSecuredAmount"
				brush={{ dataKey: 'weekNumber' }}
			/>
		</CointainerStyle>
	);
}

export default OrderSecuredChart;
