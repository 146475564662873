import React, { Fragment, useContext } from "react";

import HeaderTab from "./headerTab";
import {
	DateDisplay,
	HeaderContainer,
	HeaderTitle,
	HeaderControls,
} from "../shared/styledComponents";

import { returnLongTodaysDate } from "../../lib/getDate";
import { ThemeContext } from "../../contexts/themeContext";
import { NIGHT_MODE_KEY } from "../../constants";
import { icons } from "../../assets/icons/icons";

import config from "../../config.json";

const TW_URL = `${config.DASHBOARD_URL}/control/control_center.cgi`;
const DALE_URL = `${config.DASHBOARD_URL}/control/control_center_dl_dashboard.cgi`;
const PWG_URL = `${config.DASHBOARD_URL}/control/control_center_pwg_dashboard.cgi`;

const Header = ({permissions}) => {
	const { theme, themeKey } = useContext(ThemeContext);
	const todaysDate = returnLongTodaysDate();
	const PWGlogo = () => (
		<div className="pwg-logo-wrapper">
			{themeKey === NIGHT_MODE_KEY ? (
				<img
					className="pwg-logo-dark"
					src={icons.twHeaderLogoDark}
					alt={"dark pwg logo"}
				/>
			) : (
				<img className="pwg-logo" src={icons.twHeaderLogo} alt={"pwg logo"} />
			)}
		</div>
	);

	return (
		<>
			<style>
				{`
					.pwg-logo-wrapper {
						display:flex;
						justify-content:left;
						align-items:center;
						width:100px;
						height:100px;
						padding:0px;
					} 
					.pwg-logo, .pwg-logo-dark { 
					}
					.pwg-logo {

					 }
					.pwg-logo-dark {
						padding-top: 2px;
						width : 90px;
						height : 82px;
					}

				`}
			</style>
			<HeaderContainer className="headerContainer" theme={ theme}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<PWGlogo />
					<HeaderControls>
						<HeaderTitle theme={theme}>
							PWG CONTROL CENTRE
						</HeaderTitle>
						<DateDisplay theme={theme} ml={3} p={1}>
							{todaysDate}
						</DateDisplay>
					</HeaderControls>
				</div>

				{!permissions?.canOnlyViewMW && (
					<Fragment>
						<HeaderTab rightOffset="-2px" label="TW" link={TW_URL} />
						<HeaderTab rightOffset="94px" label="Dale" link={DALE_URL} />
						<HeaderTab rightOffset="192px" label="PWG" link={PWG_URL} />
						<HeaderTab rightOffset="290px" label="MW" isActive />
					</Fragment>
				)}
			</HeaderContainer>
		</>
	);
}

export default Header;
