import React, { useContext, useEffect, useState } from 'react';

import data from '../data';

import SimpleChart from './simpleChart';

import { CointainerStyle, PageHeading } from '../components/shared/styledComponents';
import SelectYear from '../components/shared/selectYear';

import { ThemeContext } from '../contexts/themeContext';

const EnquiriesChart = ({token}) => {
	const [pageInfo, setPageInfo] = useState({enquiries: [], selectedYear: null});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const THIS_YEAR = new Date().getFullYear();
			const enquiries = await data.getTotalEnquiriesByWeekNumber(token, THIS_YEAR);

			setPageInfo({
				token,
				selectedYear: THIS_YEAR,
				enquiries
			});
		}

		setupPage();
	}, [token]);

	async function handleEnquiriesYearChange(selectedYear) {
		const enquiries = await data.getTotalEnquiriesByWeekNumber(pageInfo.token, selectedYear);
		setPageInfo({
			token: pageInfo.token,
			selectedYear,
			enquiries
		});
	}

	return (
		<CointainerStyle theme={theme} >
			<PageHeading theme={theme}>Enquiries Chart</PageHeading>
			<SelectYear onYearChange={handleEnquiriesYearChange} />
			<SimpleChart
				chartData={pageInfo.enquiries}
				XAxisDataKey="weekNumber"
				XAxisLabel="Week Number"
				YAxisLabel="Enquiries Total"
				YAxisDataKey="totalNumberOfEnquiry"
				brush={{ dataKey: 'weekNumber' }}
			/>
		</CointainerStyle>
	);
}

export default EnquiriesChart;
