import React, { useContext } from 'react';

import { HomePageHeading } from './shared/styledComponents';
import logo from '../assets/icons/mw-logo.jpeg';
import { ThemeContext } from '../contexts/themeContext';

const Home = () => {
	const { theme } = useContext(ThemeContext);
	return(
		<div style={{ textAlign: 'center', width: '100%' }}>
			<div
				style={{
					marginTop: '40px',
					boxSizing: 'border-box',
					maxWidth: '1024px'
				}}
			>
				<img src={logo} alt={'logo'} height="100" />
			</div>

			<HomePageHeading theme = {theme}>Mumford and Wood Dashboard</HomePageHeading>
			<div>
				<p>Version 2.1.0</p>
			</div>
		</div>
	)
}

export default Home;
