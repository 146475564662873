import React, { useContext, useEffect, useState } from 'react';

import data from '../data';

import SimpleChart from './simpleChart';

import { CointainerStyle, PageHeading } from '../components/shared/styledComponents';
import SelectYear from '../components/shared/selectYear';

import { ThemeContext } from '../contexts/themeContext';

const QuotesChart = ({token}) => {
	const [pageInfo, setPageInfo] = useState({quotes: [], selectedYear: null});

	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			const THIS_YEAR = new Date().getFullYear();
			const quotes = await data.getQuotesValueByWeek(token, THIS_YEAR);

			setPageInfo({
				token,
				selectedYear: THIS_YEAR,
				quotes
			});
		}

		setupPage();
	}, [token]);

	async function handleQuotesYearChange(selectedYear) {
		const quotes = await data.getQuotesValueByWeek(token, selectedYear);
		setPageInfo({
			token: pageInfo.token,
			selectedYear,
			quotes
		});
	}

	return (
		<CointainerStyle theme={theme} >
			<PageHeading theme={theme}>Quotes Chart</PageHeading>
			<SelectYear onYearChange={handleQuotesYearChange} />
			<SimpleChart
				chartData={pageInfo.quotes}
				XAxisDataKey="weekNumber"
				XAxisLabel="Week Number"
				YAxisLabel="Quotes (£)"
				YAxisDataKey="totalQuotesAmount"
				brush={{ dataKey: 'weekNumber' }}
			/>
		</CointainerStyle>
	);
}

export default QuotesChart;
