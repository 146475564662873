import styled from "styled-components";

export const AppContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  left: 50%;
  margin-top: 0px;
  margin-left: -480px;
  font-family: ${theme.font};
  background-color: ${theme.colors.backgroundColor};
  color:${theme.colors.font};
  width: 960px;
  font-size: 13px;
`
);

export const AppContentContainer = styled.div(
  ({ theme }) => ` 
  position: absolute;
  top: 89px;
  border: 1px solid ${theme.colors.appContentBorder};
  background-color: ${theme.colors.backgroundColor};
  color:${theme.colors.font};
  width: 100%;
`
);

export const CointainerStyle = styled.div(
  ({ theme }) => `
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 25px;
  background-color: ${theme.colors.backgroundColor};
  color:${theme.colors.font};
  width: 100%;
`
);

export const FlexContainer = styled.div(
  ({ theme }) => ` 
    display: flex;
    justify-content: space-between;
    minHeight: 750px;
    height: fit-content;
    width: 100%;
`
);

export const HomeContentContainer = styled.div(
  ({ theme }) => `
  padding: 20px 20px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  margin: 0px 0px 1.5em;
  width: 690px;
  margin-top: 20px;
`
);

export const LoginFormContainer = styled.form(
  ({ theme }) => `
  width: 800px;
  margin-top: 7px;
`
);

export const RoundWrapper = styled.div(
  ({ theme }) => `
  border-color: #aaaaaa;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px;
  margin: 20px;
  padding: 20px;
  width: 960px;
`
);

export const StyledHeading = styled.span(
  ({ theme }) => `
  margin: 10px;
`
);

export const TextWrapper = styled.div(
  ({ theme }) => `
  font-weight: bold;
  margin-right: 5px;
  font-size: 9pt;
  white-space: nowrap;
`
);

export const DisplayTextInRow = styled.div(
  ({ theme }) => `
  display: table-row;
`
);

export const DisplayTextInACell = styled.div(
  ({ theme }) => `
  display: table-cell;
  width: 14%;
  align: right;
  white-space: nowrap;
`
);

export const StyledDiv = styled.div(
  ({ theme }) => `
  display: inline-block;
  margin-left: 10px;
  padding-right: 5px;
`
);

export const Button = styled.button(
  ({ theme }) => `
  cursor: pointer;
  padding: 7px 15px 7px 15px;
`
);

export const StyledButton = styled.button(
  ({ theme }) => `
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  margin-right: 60px;
  float: right;
`
);

export const Cell = styled.td(
  ({ theme }) => `
  padding: 4px;
  text-align: ${(props) => props.textAlign || "left"};
  border: solid 1px ${theme.colors.table.tableCellBorder};
  background-color: ${theme.colors.backgroundColor};
  color:${theme.colors.font};
`
);

export const DateDisplay = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.dateDisplay.backgroundColor};
  color: ${theme.colors.dateDisplay.color};
  vertical-align: center;
  padding: 5px 10px 5px 10px;
  margin-left: 20px;
`
);

export const FieldContainer = styled.div(
  ({ theme }) => `
  margin: 10px 0px;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.fieldContainer.backgroundColor};
  color:${theme.colors.fieldContainer.font}; 
`
);

export const FilterControlTitle = styled.div(
  ({ theme }) => `
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${theme.colors.border};
  
`
);

export const FixedWidthInput = styled.input(
  ({ theme }) => `
  cursor: pointer;
  width: 35%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.fixedWidthInput.color};
  background: ${theme.colors.fixedWidthInput.background};
  color:${theme.colors.fixedWidthInput.font}; 
`
);

export const StyledFixedWidthInput = styled.input(
  ({ theme }) => `
  width: 150px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.styledFixedWidthInput.color};
  background: ${theme.colors.styledFixedWidthInput.background};
  color:${theme.colors.styledFixedWidthInput.font}; 
`
);

export const StyledLabel = styled.label(
  ({ theme }) => `
  display: inline-block;
  width: 35%;
  text-align: ${(props) => props.textAlign || "right"};
  padding-right: 25px;
  vertical-align: center;
  cursor: pointer;
`
);

export const FixedWidthLabel = styled.label(
  ({ theme }) => `
  display: inline-block;
  width: 35%;
  text-align: ${(props) => props.textAlign || "right"};
  padding-right: 25px;
  vertical-align: center;
`
);

export const FixedWidthSelect = styled.select(
  ({ theme }) => `
  width: 38%;
  height: 28px;
`
);

export const HeadCell = styled.th(
  ({ theme }) => `
  padding: 4px;
  text-align: left;
  border: solid 1px ${theme.colors.table.tableCellBorder};
  color: solid 1px ${theme.colors.table.tableHeaderColor};
  white-space: nowrap;
`
);

export const TableHeader = styled.thead(
  ({ theme }) => `
  background-color: ${theme.colors.table.tableHeader};
  textAlign: "left";
`
);

export const HeaderContainer = styled.div(
  ({ theme }) => `
  margin-top: -8px;
  padding: 0px;
  height: 80px;
`
);

export const HeaderControls = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 12px;
  right: calc(50% - 480px);
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap; 
`
);

export const HeaderMenuIcon = styled.div(
  ({ theme }) => `
  text-align: center;
  color: ${theme.colors.pwg};
  height: 28px;
  width: 28px;
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`
);

export const HeaderTitle = styled.div(
  ({ theme }) => `
  color: ${theme.colors.dashboardTitle};
  font-size: 1.7em;
  letter-spacing: 3px;
`
);

export const PageHeading = styled.h1(
  ({ theme }) => `
    color: ${theme.colors.pageHeading}; 
    font-size: 16px;
  `
);

export const HomePageHeading = styled.h1(
  ({ theme }) => `
  font-size: 20px;
  color: ${theme.colors.header.colorVisited};
`
);
export const Row = styled.tr(
  ({ theme }) => `
  background-color: white;
`
);

export const SmallButton = styled.button(
  ({ theme }) => `
  cursor: pointer;
  padding: 2px 10px 2px 10px;
`
);

export const Table = styled.table(
  ({ theme }) => `
  border-collapse: collapse;
  width: 100%;
`
);

export const TableContainer = styled.div(
  ({ theme }) => `
  font-size: 13px;
  margin-top: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`
);

export const StyledTable = styled.table(
  ({ theme }) => `
  font-size: 18px;
  width: 600px;

  & tr:nth-child(even) {
    background-color: ${theme.colors.styledTable.backgroundColor};
  }

  & td {
    padding: 4px;
  } 
  color:${theme.colors.styledTable.font};
`
);

export const StyledTd = styled.td(
  ({ theme }) => `
  border-left: 1px dashed  ${theme.colors.appContentBorder};
  background-color: ${theme.colors.styledTd.backgroundColor};
`
);

export const StyledTr = styled.tr(
  ({ theme }) => `
  border-left: 1px dashed  ${theme.colors.appContentBorder}; 
  background-color: ${theme.colors.styledTr.background};
  color: ${theme.colors.styledTr.color};
`
);

export const CentredTd = styled.td(
  ({ theme }) => `
  text-align: center;
  
`
);

export const StyledStrong = styled.strong(
  ({ theme }) => `
  font-size: '16px';
`
);
