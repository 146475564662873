import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Tooltip,
} from "recharts";
import { ThemeContext } from "../contexts/themeContext";

const CustomTooltip = ({ payload, label, active, theme }) => {
  if (active) {
    let name = null;
    let value = null;
    if (payload && payload[0] && payload[0].name && payload[0].dataKey) {
      name = payload[0].name;
      value = payload[0].payload[payload[0].dataKey].toLocaleString();
    }
    return (
      <div
        style={{
          color: theme.colors.simpleChart.customToolTip.color,
          backgroundColor:
            theme.colors.simpleChart.customToolTip.backgroundColor,
        }}
      >
        <p>{`Week ${label}`}</p>
        <p>{`${name}: ${value}`}</p>
      </div>
    );
  }

  return null;
};

const SimpleChart = (props) => {
  const { theme } = useContext(ThemeContext);
  return (
    <BarChart
      width={700}
      height={700}
      data={props.chartData}
      margin={{ top: 100, right: 200, left: 105, bottom: 50 }}
    >
      <XAxis
        dataKey={props.XAxisDataKey}
        label={{
          value: props.XAxisLabel,
          position: "right",
          style: {
            padding: { left: "70px" },
          },
          fill: theme.colors.simpleChart.customToolTip.color,
          offset: 20,
        }}
        stroke={theme.colors.simpleChart.customToolTip.color}
      />
      <YAxis
        domain={props.YAxisDomain}
        ticks={props.YAxisTicks}
        tickFormatter={(tick) => {
          return tick.toLocaleString();
        }}
        label={{
          value: props.YAxisLabel,
          position: "top",
          offset: 20,
          fill: theme.colors.simpleChart.customToolTip.color,
        }}
        stroke={theme.colors.simpleChart.customToolTip.color}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar
        dataKey={props.YAxisDataKey}
        name={props.YAxisLabel}
        fill="#8884d8"
      />
      <Tooltip content={<CustomTooltip theme={theme} />} />
      <Brush height={30} stroke="#8884d8" {...props.brush} />
    </BarChart>
  );
};
export default SimpleChart;
