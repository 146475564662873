//Order Released By Week Added
import React, { useEffect, useState } from 'react';

import data from '../../data';

import SimpleChart from '../simpleChart';
import SelectYear from '../../components/shared/selectYear';

const OrderReleasedByWeekNumberChart = ({token}) => {
	const [pageInfo, setPageInfo] = useState({ordersReleasedByWeekNumber: [], selectedYear: null});

	useEffect(() => {
		const setupPage = async () => {
			const THIS_YEAR = new Date().getFullYear();
			const ordersReleasedByWeekNumber = await data.getOrdersReleasedByWeekNumber(token, THIS_YEAR);

			setPageInfo({
				token,
				selectedYear: THIS_YEAR,
				ordersReleasedByWeekNumber
			});
		}

		setupPage();
	}, [token]);

	async function handleOrderReleasedYearChange(selectedYear) {
		const ordersReleasedByWeekNumber = await data.getOrdersReleasedByWeekNumber(pageInfo.token, selectedYear);
		setPageInfo({
			token: pageInfo.token,
			selectedYear,
			ordersReleasedByWeekNumber
		});
	}

	return (
		<div>
			<SelectYear onYearChange={handleOrderReleasedYearChange} />
			<SimpleChart
				chartData={pageInfo.ordersReleasedByWeekNumber}
				XAxisDataKey="ordersReleasedWeek"
				XAxisLabel="Week Number"
				YAxisLabel="Orders Released Total (£)"
				YAxisDataKey="totalOrdersReleasedAmount"
				brush={{ dataKey: 'ordersReleasedWeek' }}
			/>
		</div>
	);
}

export default OrderReleasedByWeekNumberChart;
