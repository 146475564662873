import axios from 'axios';

import config from './config.json';

/*****************************************************************************/

const axiosPost = async (url, params) => {
	return await axios
		.post(`${config.REACT_APP_BASE_URL}${url}`, params)
		.then(function(response) {
			if (response && response.data) {
				return response.data;
			} else {
				return response;
			}
		})
		.catch(function(error) {
			// console.error('axiosPost error', error)
			// messageAlert('An error has occurred, please contact support', 3500, true);
		});
};

const axiosGet = async (url, args) => {
	let data = null;
	let fullUrl = `${config.REACT_APP_BASE_URL}${url}`;
	if (args) {
		let keys = Object.keys(args);
		for (let i = 0; i < keys.length; i++) {
			if (i === 0) {
				fullUrl += `?${keys[i]}=${args[keys[i]]}`;
			} else {
				fullUrl += `&${keys[i]}=${args[keys[i]]}`;
			}
		}
	}
	await axios
		.get(fullUrl)
		.then(response => {
			data = response.data;
		})
		.catch(error => {
			// console.error('axiosGet error', error)
			// messageAlert('An error has occurred, please contact support', 3500, true);
		});
	return data;
};
/*****************************************************************************/

const data = {
	/*****************************************************************************/
	// auth

	authenticateAuthToken: async token => {
		const url = '/authenticateToken';
		const response = await axiosPost(url, { token });
		return response;
	},

	getSystemPermissions: async token => {
		const url = '/getSystemPermissions';
		const response = await axiosPost(url, { token });
		return response;
	},

	getPersonDetails: async token => {
		const url = '/getPersonDetails';
		const response = await axiosPost(url, { token });
		return response;
	},
	/*****************************************************************************/
	// enquiries

	getEnquiry: async (token, date) => {
		const enquiry = await axiosGet('/getEnquiry', { token, date });
		return enquiry[0];
	},
	addEnquiry: async (token, params) => {
		const enquiryResponse = await axiosPost('/addEnquiry', {
			token,
			params
		});

		return enquiryResponse[0];
	},

	/*****************************************************************************/
	// quotes

	getQuoteForDate: async (token, date) => {
		const response = await axiosGet('/getQuoteForDate', { token, date });
		return response[0];
	},
	getDailyQuotingActivityPerPerson: async (token, date, roleId) => {
		const response = await axiosGet('/getDailyQuotingActivityPerPerson', {
			token,
			date,
			roleId
		});

		return response[0];
	},
	insertQuote: async (token, quoteDate, commercialReview, estimatePending, quoteDetails) => {
		const response = await axiosPost('/insertQuote', {
			token,
			quoteDate,
			commercialReview,
			estimatePending,
			quoteDetails
		});
		return response[0];
	},
	updateQuote: async (token, id, quoteDate, commercialReview, estimatePending, quoteDetails) => {
		const response = await axiosPost('/updateQuote', {
			token,
			id,
			quoteDate,
			commercialReview,
			estimatePending,
			quoteDetails
		});
		return response[0];
	},

	/****************************************************************************/
	// orderSecured

	getOrderSecuredPerPerson: async (token, date) => {
		const response = await axiosGet('/getOrderSecuredPerPerson', { token, date });
		return response[0];
	},
	addOrderSecured: async (token, ordersSecured) => {
		const response = await axiosPost('/ordersSecured/add', { token, ordersSecured });
		return response[0];
	},

	/****************************************************************************/
	// orderReleased

	getOrdersReleased: async (token, date) => {
		const orderReleased = await axiosGet('/getOrdersReleased', { token, date });
		return orderReleased[0];
	},
	insertOrderReleased: async (token, params) => {
		const orderReleased = await axiosPost('/insertOrderReleased', {
			token,
			params
		});
		return orderReleased[0];
	},
	deleteOrderReleased: async (token, id) => {
		const response = await axiosPost('/deleteOrderReleased', {
			token,
			id
		});
		if(response && response[0] && response[0].ROW_COUNT) {
			return response[0].ROW_COUNT === 1;
		}
		else {
			return 0;
		}
	},

	/****************************************************************************/
	// persons

	getAllPersons: async token => {
		const allPersons = await axiosGet('/getAllPersons', { token });
		return allPersons[0];
	},
	getPerson: async (token, id) => {
		const response = await axiosGet('/getPerson', { token, id });
		return response[0];
	},
	insertPerson: async (token, firstName, lastName, roles) => {
		const response = await axiosPost('/insertPerson', {
			token,
			firstName,
			lastName,
			roles
		});
		return response;
	},
	updatePerson: async (token, id, firstName, lastName, roles) => {
		const response = await axiosPost('/updatePerson', {
			token,
			id,
			firstName,
			lastName,
			roles
		});
		return response;
	},

	getRolesForPerson: async (token, personId) => {
		const response = await axiosGet('/getRolesForPerson', { token, personId });
		return response[0];
	},

	getRolesForPersons: async (token, roleIdArray) => {
		const response = await axiosPost('/getRolesForPersons', {
			token,
			roleIdArray
		});
		return response;
	},

	getUserPermissions: async token => {
		const response = await axiosGet('/getUserPermissions', { token });
		return response[0];
	},

	/****************************************************************************/
	// reports

	getSummaryReport: async (token, year, month) => {
		const url = `/summaryReport/${year}/${month}`;
		const reports = await axiosGet(url, { token });
		return reports[0];
	},
	getCommercialReviewAndEstimatePendingReport: async (token, date) => {
		const url = `/getCommercialReviewAndEstimatePendingReport/${date}`;
		const reports = await axiosGet(url, { token });
		return reports[0];
	},
	getTotalEnquiriesByWeekNumber: async (token, year) => {
		const url = `/enquiriesByWeek/${year}`;
		const reports = await axiosGet(url, { token });

		let enquiriesData = reports[0];
		// this should be done in the stored procedure
		for (let i = 0; i < enquiriesData.length; i++) {
			enquiriesData[i].totalNumberOfEnquiry = parseFloat(enquiriesData[i].totalNumberOfEnquiry, 10);
		}
		return enquiriesData;
	},
	getQuotesValueByWeek: async (token, year) => {
		const url = `/quotesValueByWeek/${year}`;
		const reports = await axiosGet(url, { token });

		let quotesData = reports[0];
		// this should be done in the stored procedure
		for (let i = 0; i < quotesData.length; i++) {
			quotesData[i].totalQuotesAmount = parseFloat(quotesData[i].totalQuotesAmount, 10);
		}
		return quotesData;
	},
	getOrdersSecuredByWeek: async (token, year) => {
		const url = `/ordersSecuredByWeek/${year}`;
		const reports = await axiosGet(url, { token });

		let orderSecuredData = reports[0];
		// this should be done in the stored procedure
		for (let i = 0; i < orderSecuredData.length; i++) {
			orderSecuredData[i].totalOrdersSecuredAmount = parseFloat(
				orderSecuredData[i].totalOrdersSecuredAmount,
				10
			);
		}
		return orderSecuredData;
	},
	getCommercialReviewByWeek: async (token, year) => {
		const url = `/commercialReviewByWeek/${year}`;
		const reports = await axiosGet(url, { token });
		let commercialReviewData = reports[0];

		// this should be done in the stored procedure
		for (let i = 0; i < commercialReviewData.length; i++) {
			if (commercialReviewData[i].quotesCommercialReviewCount) {
				commercialReviewData[i].quotesCommercialReviewCount = parseFloat(
					commercialReviewData[i].quotesCommercialReviewCount,
					10
				);
			} else {
				commercialReviewData[i].quotesCommercialReviewCount = 0;
			}
		}
		return commercialReviewData;
	},
	getEstimatePendingByWeek: async (token, year) => {
		const url = `/estimatePendingByWeek/${year}`;
		const reports = await axiosGet(url, { token });
		
		let estimatePendingData = reports[0];
		// this should be done in the stored procedure
		for (let i = 0; i < estimatePendingData.length; i++) {
			if (estimatePendingData[i].quotesEstimatePendingCount) {
				estimatePendingData[i].quotesEstimatePendingCount = parseFloat(
					estimatePendingData[i].quotesEstimatePendingCount,
					10
				);
			} else {
				estimatePendingData[i].quotesEstimatePendingCount = 0;
			}
		}
		return estimatePendingData;
	},
	getOrdersReleasedByWeekNumber: async (token, year) => {
		const url = `/ordersReleasedByWeek/${year}`;
		const reports = await axiosGet(url, { token });

		let orderReleasedByWeekNumberData = reports[0];
		// this should be done in the stored procedure
		for (let i = 0; i < orderReleasedByWeekNumberData.length; i++) {
			orderReleasedByWeekNumberData[i].totalOrdersReleasedAmount = parseFloat(
				orderReleasedByWeekNumberData[i].totalOrdersReleasedAmount,
				10
			);
		}
		return orderReleasedByWeekNumberData;
	},
	getOrdersReleasedByReleaseWeek: async (token, year) => {
		const url = `/ordersReleasedByReleaseWeek/${year}`;
		const reports = await axiosGet(url, { token });
		let ordersReleasedByReleaseWeekData = reports[0];

		// this should be done in the stored procedure
		for (let i = 0; i < ordersReleasedByReleaseWeekData.length; i++) {
			ordersReleasedByReleaseWeekData[i].ordersReleasedSum = parseFloat(
				ordersReleasedByReleaseWeekData[i].ordersReleasedSum,
				10
			);
		}

		return ordersReleasedByReleaseWeekData;
	}
};

export default data;
