import React, { useState } from 'react';

const THIS_YEAR = new Date().getFullYear();

function renderYears() {
	const FIRST_YEAR = 2017;
	let years = [
		<option key={2017} value={2017}>
			2017
		</option>
	];

	let nextYear;
	for (let i = 1; i < THIS_YEAR - FIRST_YEAR + 1; i++) {
		nextYear = FIRST_YEAR + i;
		years.push(
			<option key={nextYear} value={nextYear}>
				{nextYear}
			</option>
		);
	}
	return years;
}

const SelectYear = ({onYearChange}) => {
	const [dateInfo, setDateInfo] = useState({selectedYear: THIS_YEAR, years: renderYears()});

	async function handleYearChange(event) {
		const selectedYear = event.currentTarget.value;
		setDateInfo({selectedYear, years: dateInfo.years});
		await onYearChange(selectedYear);
	}

	return (
		<div>
			<span style={{ marginRight: "10px" }}>Please select a year:</span>
			<select
				style={{ marginTop: "10px" }}
				value={dateInfo.selectedYear}
				onChange={handleYearChange}
			>
				{dateInfo.years}
			</select>
		</div>
	);
}

export default SelectYear;
