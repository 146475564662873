import React, { useContext, useState } from 'react';

import { CointainerStyle, PageHeading } from '../../components/shared/styledComponents';

import OrderReleasedByWeekNumberChart from './orderReleasedByWeekNumber';
import OrderReleasedByWeekReleasedChart from './orderReleasedByWeekReleased';

import { ThemeContext } from '../../contexts/themeContext';

const OrdersReleased = ({token}) => {
	const [pageInfo, setPageInfo] = useState({graphWeekNumber: true});

	const { theme } = useContext(ThemeContext);

	async function changeGraph(newValue) {
		setPageInfo({graphWeekNumber: newValue});
	}

	return (
		<CointainerStyle theme={theme} >
			<PageHeading theme={theme}>Orders Released Chart</PageHeading>
			<select
				style={{ marginTop: '10px' }}
				width={320}
				label="Graph Type"
				onChange={() => changeGraph(!pageInfo.graphWeekNumber)}
			>
				<option value={true}>By Week Added</option>
				<option value={false}>By Production Week</option>
			</select>
			{pageInfo.graphWeekNumber ? (
				<OrderReleasedByWeekNumberChart token={token} />
			) : (
				<OrderReleasedByWeekReleasedChart token={token} />
			)}
		</CointainerStyle>
	);
}

export default OrdersReleased;
