import React, { useContext } from "react";
import { Suspense, useEffect, useState, useMemo } from "react";
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation
} from "react-router-dom";
import { useCookies } from "react-cookie";

import Home from "./components/home";
import Dashboard from "./components/dashboard";
import Enquiry from "./components/enquiries";
import Quotes from "./components/quotes";
import OrdersSecured from "./components/ordersSecured";
import OrdersReleasedForm from "./components/ordersReleased/ordersReleasedForm";

import SideMenu from "./components/sideMenu/sideMenu";
import Header from "./components/header/header";

import EnquiriesChart from "./charts/enquiries";
import QuotesChart from "./charts/quotes";
import OrderSecuredChart from "./charts/ordersSecured";
import OrdersReleased from "./charts/orderReleased";
import CommercialReviewChart from "./charts/commercialReview";
import EstimatePendingChart from "./charts/estimatePending";

import PersonsTable from "./components/persons/personsTable";
import PersonForm from "./components/persons/personForm";

import {
	AppContainer,
	AppContentContainer,
	FlexContainer,
} from "./components/shared/styledComponents";

import data from "./data";
import { ThemeContext } from "./contexts/themeContext";

import styled from "styled-components";
const TopScreenMessage = styled.div`
	position: fixed;	
	margin: 0px;
	top: -8px;
	z-index: 100;
	color: white;
	text-align: center;
	left: 28%;
	right: 28%;
	min-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;	
	flex-direction: column;
	font-family: "Verdana", sans-serif;
`;

const Message = styled.div`
	padding: 10px;
	background-color: ${props => (props.isError ? "red" : "#858b1f")};
`;

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

function MainApp() {
	let query = useQuery();
	const centreKey = query.get("token") ? query.get("token") : null;
	return (
		<div>
			<Child centreKey={centreKey} />
		</div>
	);
}

function Child({ centreKey }) {
	const [userInfo, setUserInfo] = useState({ name: "", isLoading: true });
	const { theme } = useContext(ThemeContext);
	const [messageInfo, setMessageInfo] = useState({messages:[]});

	const [cookies, setCookie] = useCookies(["ptpgToken"]);

	useEffect(() => {
		const getUserDetails = async () => {
			const token = centreKey ? centreKey : cookies.ptpgToken;

			const response = await data.authenticateAuthToken(token);

			let isAuthenticated = false;
			let isProblemAuthenticating = false;
			let name = "";
			let permissions = {};
			if (response && response.userId) {
				isAuthenticated = true;

				if (centreKey) {
					setCookie("ptpgToken", centreKey, {
						path: "/",
						maxAge: 36000,
					});
				}

				const response = await data.getPersonDetails(token);
				if (response && response.personData) {
					name = response.personData[0].staff_first_name;
				}

				const permissions_response = await data.getSystemPermissions(token);
				if (permissions_response && permissions_response.data && permissions_response.data.canOnlyViewMW) {
					permissions.canOnlyViewMW = permissions_response.data.canOnlyViewMW;
				}
			}
			else {
				isProblemAuthenticating = true;
			}

			setUserInfo({
				token,
				isAuthenticated,
				permissions,
				isProblemAuthenticating,
				name,
				isLoading: false,
			});
		};

		getUserDetails();
	}, [centreKey, cookies, setCookie]);

	function addMessage(message, milliseconds, isError) {
		let messages = [...messageInfo.messages];
		messages.push({message, isError});
		setMessageInfo({messages});
		setTimeout(removeMessageAlert, milliseconds);
	}

	function removeMessageAlert() {
		let messages = [...messageInfo.messages];
		// messages.pop();
		messages = [];
		setMessageInfo({messages});
	}

	return (
		<>
			<style >{`
				html {
					background-color: ${theme.colors.backgroundColor};
				}
			`}</style>
			<AppContainer theme={theme}>
				<Header isAuthenticated={userInfo.isAuthenticated} permissions={userInfo.permissions} />
				{messageInfo.messages.length > 0 && (
					<TopScreenMessage>
						{
							messageInfo.messages.map((note, index) => {
								return(<Message key={index} isError={note.isError}>
									{note.message}
								</Message>);
							}
						)}
					</TopScreenMessage>
				)}
				<AppContentContainer theme={theme}>
					<FlexContainer
						className={"flexContainer"}
						theme={theme}
						style={{ minHeight: "750px" }}
					>
						<SideMenu
							name={userInfo.name}
						/>
						<Suspense
							fallback={
							<div style={{ textAlign: "left", padding: "40px" }}>
								{!userInfo.isAuthenticated && (
									<div style={{ paddingBottom: "30px" }}>
										Your session token is incorrect or expired.
									</div>
								)}
								{userInfo.isLoading && <div>Loading</div>}
							</div>
							}
						>
						</Suspense>
						{userInfo.isAuthenticated && (
							<Routes>
								<Route path="/" exact element={<Home />}/>
								<Route path="/dashboard" exact element={<Dashboard token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/enquiries" exact element={<Enquiry token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/quotes" exact element={<Quotes token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/ordersSecured" exact element={<OrdersSecured token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/ordersReleased" exact element={<OrdersReleasedForm token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/personsTable" exact element={<PersonsTable token={userInfo.token} />}/>
								<Route path="/personForm/:personId?/" exact element={<PersonForm token={userInfo.token} addMessage={addMessage} />}/>
								<Route path="/chart/enquiries" exact element={<EnquiriesChart token={userInfo.token} />}/>
								<Route path="/chart/quotes" exact element={<QuotesChart token={userInfo.token} />}/>
								<Route path="/chart/ordersSecured" exact element={<OrderSecuredChart token={userInfo.token} />}/>
								<Route path="/chart/orderReleased" exact element={<OrdersReleased token={userInfo.token} />}/>
								<Route path="/chart/commercialReview" exact element={<CommercialReviewChart token={userInfo.token} />}/>
								<Route path="/chart/estimatePending" exact element={<EstimatePendingChart token={userInfo.token} />}/>
							</Routes>						
						)}
					</FlexContainer>
				</AppContentContainer>
			</AppContainer>
		</>
	);
}

export default function MumfordWoodApp() {
	return (
		<BrowserRouter>
			<MainApp />
		</BrowserRouter>
	);
}

