import { Link } from "react-router-dom";
import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../contexts/themeContext";

export const SideMenuDiv = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.sideMenu.backgroundColor};
  border-right: 1px solid ${theme.colors.appContentBorder};
  height: 100%;
  font-size: 13px;
  width: 218px;   
  min-height: 500px;
  `
);

export const SideMenuWhiteDiv = styled.div(
  ({ theme }) => ` 
  background-color: ${theme.colors.sideMenu.whiteDiv.backgroundColor};
  color:${theme.colors.sideMenu.whiteDiv.color}; 
  border-bottom: 1px solid ${theme.colors.sideMenu.whiteDiv.border};
  margin-top: 0px;
  padding:  8px 8px 8px 15px;
`
);

export const SideMenuLinkStyle = styled(Link)(
  ({ theme, ...props }) => `
  padding: 8px;
  padding-left: 15px;
  display: block;
  border-bottom: 1px solid ${theme.colors.sideMenu.linkStyle.border};
  border-width: thin;
  border-color: ${theme.colors.sideMenu.linkStyle.border}; 
  color:${theme.colors.sideMenu.linkStyle.color}; 
  text-decoration: none;
  &:hover {
    text-decoration: ${props.params.isSelected ? "none" : "underline"};
  }
  font-weight: ${props.params.isSelected ? "bold" : "normal"};
  background-color: ${
    props.params.isSelected
      ? theme.colors.sideMenu.linkStyle.backgroundSelected
      : theme.colors.sideMenu.linkStyle.backgroundUnselected
  };
`
);

export const SideMenuLink = (props) => {
  const { theme } = useContext(ThemeContext);
  return (
    <SideMenuLinkStyle
      theme={theme}
      id={props.id}
      to={props.to}
      children={props.children}
      params={{ isSelected: props.isSelected }}
    />
  );
};

export const Icon = styled.img`
  cursor: pointer;
`;
