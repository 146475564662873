import calendarIcon from "./calendar-icon-small.png";
import editIcon from "./edit.gif";
import emailIcon from "./email.gif";
import helpIcon from "./help_icon.gif";
import smileyFaceIcon from "./smile.gif";
import twHeaderLogo from "./pwg-tree-logo-90-82.png";
import twHeaderLogoDark from "./pwg-tree-logo.transparent.dark.png";
import viewIcon from "./view.gif";

export const icons = {
  calendarIcon,
  editIcon, 
  emailIcon,
  helpIcon,
  smileyFaceIcon,
  twHeaderLogo,
  twHeaderLogoDark,
  viewIcon
};
