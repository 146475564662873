import moment from 'moment';

const TODAYS_DATE = new Date();

export const dateString = date => {
	let readableDate = '';
	// Assumes int value?
	if (date % 20 === 1 || date % 30 === 1) {
		readableDate = `${date}st`;
	} else if (date % 20 === 2) {
		readableDate = `${date}nd`;
	} else if (date % 20 === 3) {
		readableDate = `${date}rd`;
	} else {
		readableDate = `${date}th`;
	}
	return readableDate;
};

export const todaysDate = () => {
	const date = `${TODAYS_DATE.getFullYear()}-${('0' + (TODAYS_DATE.getMonth() + 1)).slice(-2)}-${(
		'0' + TODAYS_DATE.getDate()
	).slice(-2)}`;
	return date;
};

export const previousWeekDayDate = () => {
	let dateSubtraction = 1;
	let todaysDate = moment().isoWeekday();
	if (todaysDate === 7) {
		dateSubtraction = 2;
	} else if (todaysDate === 1) {
		dateSubtraction = 3;
	}
	const yesterdayDate = moment().subtract(dateSubtraction, 'day');
	return yesterdayDate;
};

export const returnDate = date => {
	const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
		'0' + date.getDate()
	).slice(-2)}`;
	return formattedDate;
};

export const returnLongTodaysDate = date => {
	const weekDays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday'
	];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	return `${weekDays[TODAYS_DATE.getDay()]} ${dateString(TODAYS_DATE.getDate())} ${
		months[TODAYS_DATE.getMonth()]
	} ${TODAYS_DATE.getFullYear()}`;
};
