import React, { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import data from '../data';

import SelectDate from './shared/selectDate';
import { todaysDate } from '../lib/getDate';
import {
	PageHeading,
	FixedWidthLabel,
	FieldContainer,
	CointainerStyle,
	StyledButton,
	StyledFixedWidthInput
} from './shared/styledComponents';
import { ThemeContext } from '../contexts/themeContext';

/*****************************************************************************/

const moneyStyle = {
	cursor: 'pointer',
	width: '150px',
	padding: '8px',
	borderRadius: '5px',
	border: '1px solid #dedede'
};

function checkCostIsAllowed(values) {
	const { floatValue } = values;
	return floatValue >= 0 && floatValue <= 9999999.99;
}

/*****************************************************************************/

const OrdersSecured = ({token, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({orderSecuredDetails: [], orderSecuredDate: null});
	
	const { theme } = useContext(ThemeContext);

	useEffect(() => {
		const setupPage = async () => {
			let orderSecuredDetails = [];
			let date = todaysDate();
			const response = await data.getOrderSecuredPerPerson(token, date);
			if (response) {
				orderSecuredDetails = response;
			}
			setPageInfo({orderSecuredDetails, orderSecuredDate: date});
		}
		
		setupPage();
	}, [token]);

	async function onHandleSubmit(event) {
		event.preventDefault();
		const orderSecuredData = {
			orderSecuredDate: pageInfo.orderSecuredDate,
			orderSecuredDetails: pageInfo.orderSecuredDetails
		};
		const orderSecuredAddResponse = await data.addOrderSecured(token, orderSecuredData);
		if (orderSecuredAddResponse && orderSecuredAddResponse !== '') {
			addMessage('The order secured has been successfully saved', 3500, false);
		}
		else {
			addMessage('An error has occured, please try again or contact support', 3500, true);
		}
	}

	async function handleOrderSecuredDateChange(date) {
		let orderSecuredDetails = [];
		const response = await data.getOrderSecuredPerPerson(token, date);
		if (response) {
			orderSecuredDetails = response;
		}
		setPageInfo({orderSecuredDetails, orderSecuredDate: date});
	}

	async function handleInputChange(index, event) {
		let orderSecuredDetails = [...pageInfo.orderSecuredDetails];
		orderSecuredDetails[index][event.target.name] = event.target.value;
		setPageInfo({orderSecuredDetails, orderSecuredDate: pageInfo.orderSecuredDate});
	}

	async function handleCostChange(index, values) {
		const { value } = values;
		let orderSecuredDetails = [...pageInfo.orderSecuredDetails];
		orderSecuredDetails[index].totalCost = value;
		setPageInfo({orderSecuredDetails, orderSecuredDate: pageInfo.orderSecuredDate});
	}

	return (
		<CointainerStyle theme={theme}>
			<PageHeading theme={theme} >Orders Secured</PageHeading>
			<br />
			<form onSubmit={onHandleSubmit} autoComplete="off">
				<SelectDate theme={theme} onDateChange={handleOrderSecuredDateChange} />
				<hr />
				{pageInfo.orderSecuredDetails &&
					pageInfo.orderSecuredDetails.map((orderSecured, index) => {
						return (
							<FieldContainer theme={theme} key={index}>
								<FixedWidthLabel theme={theme}>{orderSecured.firstName}:</FixedWidthLabel>
								<StyledFixedWidthInput
									theme={theme}
									type="number"
									name={'totalNumber'}
									min={0}
									max={99999}
									required={true}
									style={{ marginRight: '10px' }}
									value={orderSecured.totalNumber ? orderSecured.totalNumber : 0}
									onChange={event => handleInputChange(index, event)}
								/>
								<NumericFormat
									theme={theme}
									style={moneyStyle}
									thousandSeparator={','}
									decimalSeparator={'.'}
									precision={5}
									decimalScale={2}
									prefix={'£ '}
									value={orderSecured.totalCost ? orderSecured.totalCost : 0}
									valueIsNumericString={true}
									onValueChange={values => handleCostChange(index, values)}
									isAllowed={values => checkCostIsAllowed(values)}
								/>
							</FieldContainer>
						);
					})}
				<br />
				<StyledButton theme={theme} type="submit">Save</StyledButton>
			</form>
			<br />
		</CointainerStyle>
	);
}

export default OrdersSecured;
