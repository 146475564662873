import React, { useContext, useState } from 'react';

import data from '../data';
import {
	PageHeading,
	FixedWidthLabel,
	FixedWidthInput,
	FieldContainer,
	CointainerStyle
} from './shared/styledComponents';

import { todaysDate } from '../lib/getDate';
import SelectDate from './shared/selectDate';
import { ThemeContext } from '../contexts/themeContext';

const Enquiries = ({token, addMessage}) => {
	const [pageInfo, setPageInfo] = useState({byEmail: 0, byPhone: 0, byBrochure: 0, byPost: 0, enquiryDate: todaysDate()});

	const { theme } = useContext(ThemeContext);
	
	const handleSubmit = async (event) => {
		event.preventDefault();

		const enquiryAddResponse = await data.addEnquiry(token, pageInfo);
		if (enquiryAddResponse && enquiryAddResponse !== '' && enquiryAddResponse.id) {
			addMessage('Enquiries for this date have been successfully saved', 3500, false);
		}
		else {
			addMessage('An error has occured, please try again or contact support', 3500, true);
		}
	}

	const handleEnquiriesDateChange = async (date) => {
		let enquiryForSelectedDate = await data.getEnquiry(token, date);
		if (!enquiryForSelectedDate) {
			enquiryForSelectedDate = {
				byEmail: 0,
				byPhone: 0,
				byBrochure: 0,
				byPost: 0
			};
		}
		enquiryForSelectedDate.enquiryDate = date;
		setPageInfo(enquiryForSelectedDate);
	}

	const handleInputChange = async (event) => {
		event.preventDefault();
		let newState = {...pageInfo};
		newState[event.target.name] = parseInt(event.target.value);
		setPageInfo({...newState});
	}

	return (
		<CointainerStyle theme={theme}>
			<PageHeading theme={theme}>Enquiries</PageHeading>
			<br />
			<form onSubmit={event => handleSubmit(event)} autoComplete="off">
				<SelectDate onDateChange={handleEnquiriesDateChange} />
				<hr />
				<br />
				<FieldContainer theme={theme}>
					<FixedWidthLabel theme={theme}>Enquiries:</FixedWidthLabel>
					<FixedWidthInput 
						theme={theme}
						type="number"
						name={'byEmail'}
						min={0}
						max={99999}
						required={true}
						value={pageInfo.byEmail ? pageInfo.byEmail : 0}
						onChange={event => handleInputChange(event)}
					/>
				</FieldContainer>
				<br />
				<button
					style={{
						float: 'right',
						cursor: 'pointer',
						marginRight: '155px',
						padding: '2px 10px 2px 10px'
					}}
					type="submit"
				>
					Save
				</button>
			</form>
			<br />
		</CointainerStyle>
	);
}

export default Enquiries;
